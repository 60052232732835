import HeepsyForm from './HeepsyForm';
import './landing.scss';
export default function Landing({ content, highlightedQuestion, thankYou, isDashboard }) {
  const { photoUrl, title, text, styles, formInformation } = content || {};
  return (
    <div
      className="landing-editor__preview"
      style={{
        borderRadius: styles?.borderRadius || 12,
        backgroundColor: styles?.backgroundColor || '#f5f5f5',
        color: styles?.color || '#19202B',
      }}
    >
      {photoUrl && <img src={photoUrl} alt="Landing" />}
      {title && <h1 style={{ paddingInline: 16, margin: '1rem 0', color: styles?.color || '#19202B' }}>{title}</h1>}
      {text && <p style={{ paddingInline: 16, margin: '1rem 0', color: styles?.color || '#19202B' }}>{text}</p>}

      <HeepsyForm
        formInformation={formInformation}
        highlightedQuestion={highlightedQuestion}
        thankYou={thankYou}
        isDashboard={isDashboard}
      />
    </div>
  );
}
