import interceptor from './interceptor.service';

const config = { apiUrl: process.env.REACT_APP_API_URL };

function handleResponse(response) {
  switch (response.status) {
    case 500:
      return {status: 500, data: null};
    case 200:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return data;
      });
    default:
      return {status: response?.status, data: null};
  }
}

async function getFeature() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
  };
  return interceptor(`${config.apiUrl}/api/feature`, requestOptions)
    .then(handleResponse)
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getFeature
};
