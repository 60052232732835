
import interceptor from './interceptor.service';
import { hardRefresh, shouldUpdate } from 'utils/hardRefresh';
import { updateUserInfo, shouldUpdateUserInfo } from 'utils/userInfo';
import { version } from 'version';

function handleResponse(response) {
  if (shouldUpdateUserInfo(response)) updateUserInfo(response);
  if (shouldUpdate(response)) hardRefresh();
  if (response.status === 200) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      return { status: 200, data };
    });
  }
  if (response.text === 204) {
    return { status: 204, deleted: true };
  }

  switch (response.status) {
    case 400:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return { status: 400, data };
      });
    case 500:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return { status: 500, data };
      });
    case 504:
      return { status: 504, data: null };
    case 503:
      return { status: 503, data: null };
    default:
      return { status: response.status, data: null };
  }
}

function createLanding(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Version: version,
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
    body: JSON.stringify(data),
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/landings/create`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) return data;
      const error = { status, data };
      return Promise.reject(error);
    });
}

function editLandingBasic(id, data) {

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Version: version,
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
    body: JSON.stringify(data),
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/landings/edit_basic/${id}`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) return data;
      const error = { status, data };
      return Promise.reject(error);
    });
}

function editLandingContent(id, data) {

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Version: version,
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
    body: JSON.stringify(data),
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/landings/edit_content/${id}`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) return data;
      const error = { status, data };
      return Promise.reject(error);
    });
}

function deleteLanding(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Version: version,
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/landings/delete/${id}`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) return data;
      const error = { status, data };
      return Promise.reject(error);
    });
}

function getLandings() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Version: version,
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/landings`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) return data;
      const error = { status, data };
      return Promise.reject(error);
    });
}

async function getLanding(id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Version: version,
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/landings/${id}`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) return data;
      const error = { status, data };
      return Promise.reject(error);
    });
}

async function checkPathAvailability(id, path) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Version: version,
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
    body: JSON.stringify({ id, path }),
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/landings/check_path`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) return data;
      const error = { status, data };
      return Promise.reject(error);
    });
}


async function getPublicLanding(id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Version: version,
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/landings/public/${id}`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) return data;
      const error = { status, data };
      return Promise.reject(error);
    });
}


async function uploadPhoto(file) {
  const formData = new FormData();
  formData.append('file', file);

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
    body: formData,
  };
  return interceptor(`${process.env.REACT_APP_API_URL}/api/landings/upload_photo`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) return data;
      const error = { status, data };
      return Promise.reject(error);
    });

}


async function sendLandingResponse (formData) {

  const apiServerURL = process.env.REACT_APP_API_URL;
  const thislanding = window.location.href.split('/public/').pop();
  const endpoint = `${apiServerURL}/api/landings/responses/${thislanding}`;

  const requestOptions = {
    method: 'POST',
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // },
    body: formData,
  };
  
  return interceptor(endpoint, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) return data;
      const error = { status, data };
      return Promise.reject(error);
    });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createLanding,
  editLandingBasic,
  editLandingContent,
  checkPathAvailability,
  deleteLanding,
  getLandings,
  getLanding,
  getPublicLanding,
  uploadPhoto,
  sendLandingResponse
};
