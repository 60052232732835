import { LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Modal, Select, Space, Spin, Upload, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuExternalLink } from 'react-icons/lu';

import './landing.scss';

export default function HeepsyForm({ formInformation, highlightedQuestion, thankYou, isDashboard }) {
  const { t } = useTranslation('common');
  const formRefs = useRef({});
  const [form] = Form.useForm();
  const { style = {}, submitButton = {}, questions = [] } = formInformation || {};
  const [isThankyouVisible, setIsThankyouVisible] = useState(false);
  const [isModalSubmitNotActive, setIsModalSubmitNotActive] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // const [isError, setIsError] = useState(false);
  const isMounted = useRef(true);

  const { Dragger } = Upload;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []); 

  const scrollToHighlighted = () => {
    if (highlightedQuestion && formRefs.current[highlightedQuestion]) {
      formRefs.current[highlightedQuestion].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  useEffect(() => {
    scrollToHighlighted();
  }, [highlightedQuestion]); // eslint-disable-line react-hooks/exhaustive-deps

 
  const submitFunction = async (formData) => {
    // setIsError(false);
    setSubmitting(true);
    const apiServerURL = process.env.REACT_APP_API_URL;
    const thislanding = window.location.href.split('/public/').pop();
    const endpoint = `${apiServerURL}/api/landings/responses/${thislanding}`;
    if (isDashboard) {
      setIsModalSubmitNotActive(true);
      setSubmitting(false);
      return;
    }
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: formData, // Send FormData instead of JSON
        headers: {
          ContentType: 'multipart/form-data',
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Server Error: ${errorText}`);
        if (isMounted.current) {
          message.error(t(`landings.form.responses.${JSON.parse(errorText).message}`));
          // setIsError(true);
        }
      }
      if (isMounted.current) {
        setIsThankyouVisible(true);
      }
    } catch (error) {
      console.error('Request failed:', error);
      // if (isMounted.current) {
      //   setIsError(true);
      // }
    } finally {
      if (isMounted.current) {
        setSubmitting(false);
      }
    }
  };

  const handleFinish = (values) => {
    const formData = new FormData();

    for (const key in values) {
      if (key !== 'file') {
        formData.append(key, values[key]);
      }
    }

    if (values.file) {
      formData.append('file', values.file);
    }

    submitFunction(formData);
  };

  const getAutoComplete = (type) => {
    switch (type) {
      case 'email':
        return 'email';
      case 'tel':
        return 'tel';
      case 'name':
        return 'name';
      default:
        return 'off';
    }
  };

  const getValidationRules = (question) => {
    const rules = [];

    if (question.required) {
      if (question.type === 'checkbox' || question.type === 'privacy') {
        rules.push({
          validator: (_, value) =>
            value ? Promise.resolve() : Promise.reject(new Error('You must accept this field')),
        });
      } else {
        rules.push({
          required: true,
          message: `This field is mandatory`,
        });
      }
    }

    switch (question.type) {
      case 'email':
        rules.push({
          type: 'email',
          message: 'Please enter a valid email address.',
        });
        break;
      case 'tel':
        rules.push({
          pattern: /^[0-9+\-\s()]*$/,
          message: 'Please enter a valid phone number.',
        });
        break;
      default:
        break;
    }

    return rules;
  };

  const getInputProps = (question) => {
    const baseProps = {
      placeholder: question.placeholder || question.question,
      autoComplete: getAutoComplete(question.type),
    };

    switch (question.type) {
      case 'email':
        return {
          ...baseProps,
          type: 'email',
          autoComplete: 'email',
        };
      case 'tel':
        return {
          ...baseProps,
          type: 'tel',
          autoComplete: 'tel',
        };
      case 'text':
      default:
        return {
          ...baseProps,
          type: 'text',
          autoComplete: question.ref === 'name' ? 'name' : 'off',
        };
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e[0]?.originFileObj; // return just the last uploaded file
    }
    return e?.fileList?.[0]?.originFileObj; // return just the last uploaded file
  };

  // const checkFileSize = (file) => {
  //   const isLt2M = file.size / 1024 / 1024 < 200; // 200MB
  //   if (!isLt2M) {
  //     message.error('File must be smaller than 200MB');
  //   }
  //   return isLt2M;
  // };

  const uploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    action: '',
    beforeUpload(file) {
      const isLt200M = file.size / 1024 / 1024 < 200;
      if (!isLt200M) {
        message.error('File must be smaller than 200MB');
      }
      return isLt200M ? false : Upload.LIST_IGNORE;
    },
  };

  const renderField = (question) => {
    switch (question.ref) {
      case 'instagram': {
        return (
          <div ref={(el) => (formRefs.current[question.id] = el)}>
            <Form.Item
              key={question.id}
              label={<label style={{ color: style.color || '#000' }}>{question.question}</label>}
              required
              className={highlightedQuestion === question.id ? 'highlighted-question' : ''}
            >
              <Space.Compact style={{ width: '100%' }}>
                <Input style={{ width: '40%' }} disabled value="https://www.instagram.com/" />
                <Form.Item key={question.id} name={question.ref} noStyle rules={getValidationRules(question)}>
                  <Input {...getInputProps(question)} style={{ width: '100%' }} />
                </Form.Item>
              </Space.Compact>
            </Form.Item>
          </div>
        );
      }
      case 'tiktok': {
        return (
          <Form.Item
            key={question.id}
            label={<label style={{ color: style.color || '#000' }}>{question.question}</label>}
            required={question.required}
            className={highlightedQuestion === question.id ? 'highlighted-question' : ''}
          >
            <div ref={(el) => (formRefs.current[question.id] = el)}>
              <Space.Compact style={{ width: '100%' }}>
                <Input style={{ width: '40%' }} disabled value="https://www.tiktok.com/" />
                <Form.Item key={question.id} name={question.ref} noStyle rules={getValidationRules(question)}>
                  <Input {...getInputProps(question)} style={{ width: '100%' }} />
                </Form.Item>
              </Space.Compact>
            </div>
          </Form.Item>
        );
      }
      case 'youtube': {
        return (
          <div ref={(el) => (formRefs.current[question.id] = el)}>
            <Form.Item
              key={question.id}
              label={<label style={{ color: style.color || '#000' }}>{question.question}</label>}
              required={question.required}
              className={highlightedQuestion === question.id ? 'highlighted-question' : ''}
            >
              <Space.Compact style={{ width: '100%' }}>
                <Input style={{ width: '40%' }} disabled value="https://www.youtube.com/" />
                <Form.Item key={question.id} name={question.ref} noStyle rules={getValidationRules(question)}>
                  <Input {...getInputProps(question)} style={{ width: '100%' }} />
                </Form.Item>
              </Space.Compact>
            </Form.Item>
          </div>
        );
      }
      default:
        break;
    }
    switch (question.type) {
      case 'select': {
        return (
          <div ref={(el) => (formRefs.current[question.id] = el)}>
            <Form.Item
              key={question.id}
              label={<label style={{ color: style.color || '#000' }}>{question.question}</label>}
              name={question.ref}
              rules={getValidationRules(question)}
              className={highlightedQuestion === question.id ? 'highlighted-question' : ''}
            >
              <Select placeholder={question.placeholder || question.question} mode="multiple" allowClear>
                {question.options?.map((opt) => (
                  <Select.Option key={opt.id} value={opt.value}>
                    {opt.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        );
      }
      case 'checkbox':
        return (
          <div ref={(el) => (formRefs.current[question.id] = el)}>
            <Form.Item
              key={question.id}
              name={question.ref}
              rules={getValidationRules(question)}
              valuePropName="checked"
              className={highlightedQuestion === question.id ? 'highlighted-question ' : ''}
            >
              <Checkbox style={{ whiteSpace: 'normal', lineHeight: '1.5' }}>
                {question.required && <span style={{ color: 'red', marginRight: '4px' }}>*</span>}
                <span style={{ display: 'inline-block', wordBreak: 'break-word' }}>{question.question}</span>
              </Checkbox>
            </Form.Item>
          </div>
        );
      case 'privacy':
        return (
          <div ref={(el) => (formRefs.current[question.id] = el)}>
            <Form.Item
              key={question.id}
              name={question.ref}
              rules={getValidationRules(question)}
              valuePropName="checked"
              className={highlightedQuestion === question.id ? 'highlighted-question ' : ''}
            >
              <Checkbox style={{ whiteSpace: 'normal', lineHeight: '1.5' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {question.required && <span style={{ color: 'red', marginRight: '4px' }}>*</span>}
                  <span style={{ display: 'inline-block', wordBreak: 'break-word', marginRight: 2 }}>
                    {question.question}
                  </span>
                  <a href={question.link} target="_blank" rel="noreferrer" className="d-inline-flex align-items-center">
                    <LuExternalLink fontSize={16} />
                  </a>
                </div>
              </Checkbox>
            </Form.Item>
          </div>
        );
      case 'file': {
        return (
          <div ref={(el) => (formRefs.current[question.id] = el)} className="d-flex">
            <Form.Item
              name={question.ref}
              key={question.id}
              label={<label style={{ color: style.color || '#000' }}>{question.question}</label>}
              valuePropName="file"
              getValueFromEvent={normFile}
              extra="Max file size: 200MB"
              style={{ width: '100%' }}
              rules={getValidationRules(question)} 
            >
              <Dragger
                {...uploadProps}
                style={{
                  paddingInline: 16,
                  width: '100%',
                  borderColor: highlightedQuestion === question.id ? '#04a946' : 'gray',
                }}
              >
                <p className="ant-upload-text mb-0">{question.placeholder}</p>
              </Dragger>
            </Form.Item>
          </div>
        );
      }
      default:
        return (
          <div ref={(el) => (formRefs.current[question.id] = el)}>
            <Form.Item
              key={question.id}
              label={<label style={{ color: style.color || '#000' }}>{question.question}</label>}
              name={question.ref}
              rules={getValidationRules(question)}
              className={highlightedQuestion === question.id ? 'highlighted-question' : ''}
            >
              <Input {...getInputProps(question)} />
            </Form.Item>
          </div>
        );
    }
  };

  const renderThankYou = () => {
    const thankYouMessage = formInformation.thankyou?.message || 'Thank you! We will contact you soon';
    const thankYouSubtitle = formInformation.thankyou?.subtitle || 'Check your inbox and follow the steps to finish the validation process.';
    return (
      <div
        style={{
          textAlign: 'center',
          color: formInformation.thankyou?.color || '#000',
          backgroundColor: formInformation.thankyou?.backgroundColor || '#fff',
          padding: '1rem',
          borderRadius: '8px',
        }}
      >
        <h2>{thankYouMessage}</h2>
        <p style={{ fontSize: '0.9rem', marginTop: '0.5rem' }}>{thankYouSubtitle}</p>
      </div>
    );
  };

  return (
    <div
      className="heepsy-form"
      style={{
        backgroundColor: style.backgroundColor || '#fff',
        color: style.color || '#fff',
      }}
    >
      {thankYou || isThankyouVisible ? (
        renderThankYou()
      ) : (
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          {questions.map((q) => (
            <React.Fragment key={q.id}>{renderField(q)}</React.Fragment>
          ))}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: submitButton.backgroundColor || '#123456',
                color: submitButton.color || '#fff',
                pointerEvents: submitting ? 'none' : 'auto',
              }}
            >
              {submitting ? (
                <Spin
                  indicator={<LoadingOutlined style={{ color: submitButton.color || '#fff' }} spin />}
                  size="small"
                />
              ) : (
                submitButton.text || 'Submit'
              )}
            </Button>
          </Form.Item>
        </Form>
      )}
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        Powered by{' '}
        <a href="https://www.heepsy.com" target="_blank" rel="noreferrer">
          Heepsy
        </a>
      </div>
      {isModalSubmitNotActive && (
        <Modal
          title="Form submission not active"
          open
          centered
          footer={null}
          onCancel={() => setIsModalSubmitNotActive(false)}
        >
         {t('landings.form.responses.form_not_active')}
        </Modal>
      )}
    </div>
  );
}
