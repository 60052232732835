import { Tag } from "antd";
import { useState, useEffect } from "react";

const NewFeatureTag = ({ showMoreOption }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 1000);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Tag
      color="#fff"
      bordered
      className={animate ? "rotate-pulse" : ""}
      style={{ 
        fontSize: "14px", 
        fontWeight: "bold", 
        backgroundColor: "#00A742",
        top: 0, 
        left: showMoreOption ? 5 : 25
      }}
    >
      New
    </Tag>
  );
};

export default NewFeatureTag;

