import * as queryParamsConfig from 'assets/config/queryParamsConfig';

const keyMapping = {
  followersFilter: queryParamsConfig.CREATORS_FILTER_FOLLOWERS,
  engagementFilter: queryParamsConfig.CREATORS_FILTER_ENGAGEMENT,
  hashtagsFilter: queryParamsConfig.CREATORS_FILTER_HASHTAGS,
  excludeHashtagsFilter: queryParamsConfig.CREATORS_FILTER_EXCLUDE_HASHTAGS,
  excludeMentionsFilter: queryParamsConfig.CREATORS_FILTER_EXCLUDE_MENTIONS,
  mentionsFilter: queryParamsConfig.CREATORS_FILTER_MENTIONS,
  verifiedFilter: queryParamsConfig.CREATORS_FILTER_VERIFIED,
  contactableFilter: queryParamsConfig.CREATORS_FILTER_CONTACTABLE,
  optedInFilter: queryParamsConfig.CREATORS_FILTER_IS_MOONIO,
  brandsFilter: queryParamsConfig.CREATORS_FILTER_EXCLUDE_BRANDS,
  creatorsFilter: queryParamsConfig.CREATORS_FILTER_EXCLUDE_CREATORS,
  audienceAgeFilter: queryParamsConfig.CREATORS_FILTER_AUDIENCE_AGE_FILTER,
  audienceCatFilter: queryParamsConfig.CREATORS_FILTER_AUDIENCE_CAT_FILTER,
  audienceLocationFilter: queryParamsConfig.CREATORS_FILTER_AUDIENCE_LOC_FILTER,
  audienceLanguageFilter: queryParamsConfig.CREATORS_FILTER_AUDIENCE_LAN_FILTER,
  audienceGenderFilter: queryParamsConfig.CREATORS_FILTER_AUDIENCE_GEN_FILTER,
  audienceAuthenticityFilter: queryParamsConfig.CREATORS_FILTER_AUDIENCE_IS_REAL_PERCENTAGE_FILTER,
  customSearch: queryParamsConfig.CREATORS_CUSTOM_SEARCH,
  locationFilter: queryParamsConfig.CREATORS_LOCATION_SEARCH,
  coordinatesFilter: queryParamsConfig.CREATORS_COORDINATES_SEARCH,
  excludeCoordinatesFilter: queryParamsConfig.CREATORS_EXCLUDE_COORDINATES_SEARCH,
  lookalikeFilter: queryParamsConfig.CREATORS_FILTER_LOOKALIKE,
  avgLikesPerPostFilter: queryParamsConfig.CREATORS_FILTER_AVG_LIKES_PER_POST,
  avgCommentsPerPostFilter: queryParamsConfig.CREATORS_FILTER_AVG_COMMENTS_PER_POST,
  estimatedReachFilter: queryParamsConfig.CREATORS_FILTER_ESTIMATED_REACH,
  genderFilter: queryParamsConfig.CREATORS_FILTER_GENDER,
  averageVideoViewsCountFilter: queryParamsConfig.CREATORS_FILTER_AVERAGE_VIDEO_VIEWS_COUNT,
  totalVideoViewsCountFilter: queryParamsConfig.CREATORS_FILTER_TOTAL_VIDEO_VIEWS_COUNT,
  excludedListsFilter: queryParamsConfig.CREATORS_FILTER_EXCLUDED_LISTS,
  excludeKeywordsFilter: queryParamsConfig.CREATORS_FILTER_EXCLUDE_KEYWORDS,
  excludeLocationFilter: queryParamsConfig.CREATORS_FILTER_EXCLUDE_LOCATION,
  aqsFilter: queryParamsConfig.CREATORS_FILTER_AQS,
  costPerPostFilter: queryParamsConfig.CREATORS_FILTER_COST_PER_POST,
  followersGrowthFilter: queryParamsConfig.CREATORS_FILTER_FOLLOWERS_GROWTH,
  exactSearchMatch: queryParamsConfig.EXACT_SEARCH_MATCH,
};

export default keyMapping;
