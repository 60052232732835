import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

import Loader from 'components/Loader/Loader';
import Landing from './Landing';
import landingsService from 'services/landings.service';

import '../LandingEditor/landingEditor.scss';

export default function PublicLandingWrapper() {
  const { path } = useParams();
  const [landingContent, setLandingContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const root = document.querySelector('#root');

    root.classList.add('no-padding-all');
  }, []);

  useEffect(() => {
    async function fetchLanding() {
      try {
        setIsLoading(true);
        const landing = await landingsService.getPublicLanding(path);
        setLandingContent(landing.content);
      } catch (error) {
        console.error('Error fetching landing:', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchLanding();
  }, [path]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="public-landing">
      <Landing content={landingContent} />
    </div>
  );
}
