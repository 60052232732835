import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

import './Loader.scss';
import OptedIcon from 'components/Icons/OptedIcon';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 40,
      color: "#00A742"
    }}
    spin
  />
);

const Loader = ({ waitForResponse, isHandleSearch, refreshClicked, isDrawer, isInviteLoading }) => {
  const { t } = useTranslation('common');

  const [showInfo, setShowInfo] = useState(null);

  useEffect(() => {
    if (waitForResponse || isHandleSearch || refreshClicked || isInviteLoading) {
      const timer = setTimeout(() => {
        setShowInfo(isHandleSearch ? 'loaderSearch' : isInviteLoading ? 'inviteSearch' : 'loaderProfile');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isHandleSearch, waitForResponse, isInviteLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className={classNames('loader', { '-is-drawer': isDrawer, 'mt-10': isInviteLoading && !isMobile })}>
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="loader__container">
            <Spin indicator={antIcon} />
            <div className="loader__container-logo">
              <OptedIcon color="#00A742" />
            </div>

            {showInfo && (
              <div
                className={classNames({
                  'mt-10': isMobile,
                  'ml-2 text-center loader__search-info': showInfo,
                  'ml-2': showInfo !== 'loaderSearch',
                })}
              >
                {!isMobile ? (
                  <span className="text-center">{t(`report.table.${showInfo}`)}</span>
                ) : (
                  <div className="d-flex flex-column text-center pt-3">
                    <span>{t(`report.table.${showInfo}1`)}</span>
                    <span>{t(`report.table.${showInfo}2`)}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Loader);
