import { useEffect } from 'react';

import OfferCloseIcon from 'components/Icons/OfferCloseIcon';
import './feature-modal.scss';

export default function FeatureModal({ open, toggleVisibility, feature }) {

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  const lang = JSON.parse(localStorage.getItem('user'))?.user?.lang;
  const image = feature?.[`${lang}`]?.image_url

  return open ? (
    <>
      <div className='modal-backdrop' />
      <div
        className="modal"
        onClick={() => {
          toggleVisibility();
          window.open(feature[`${lang}`]?.redirection_url, '_blank');
        }}
        style={{
          backgroundImage: `url("${image}")`, 
          backgroundSize: 'contain', 
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center' 
        }}
      >
        <div className="modal-close-icon" onClick={(e) => {
          e.stopPropagation();
          toggleVisibility();
        }}>
          <OfferCloseIcon width={28} height={28} fill='#6f7585' />
        </div>
      </div>
    </>
  ) : null;
}